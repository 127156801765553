import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from '../../components/en/common/Layout'
import { MetaData } from '../../components/common/meta'
import HeroFullScreenPhoto from '../../components/sections/hero-full-screen-photo'
import Partnerships from '../../components/en/common/partnerships'
import OurPartners from '../../components/sections/our-partners'
import Testimonials from '../../components/en/sections/testimonials-static'
import WhyThinkPlus from '../../components/en/sections/why-think-plus'
import SectionHeader from '../../components/common/section-header'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import ContactForm from '../../components/en/sections/contact-form'

import '../../styles/pages/page-landingpage.scss'
import { faqShopify as faqSchema } from '../../utils/schema/en/faq'
import { shopify as servicesSchema } from '../../utils/schema/en/services'

const pageShopifyDevelopment = ({ location }) => {
  const data = require('../../utils/schema/en/pages/shopifyDevelopment.json')
  const images = useStaticQuery(graphql`{
  hero: file(relativePath: {eq: "hero/hero-shopify.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
	caseStudy_aek: file(relativePath: {eq: "eshopshopify/tp-casestudy-aek.png"}) {
		childImageSharp {
			gatsbyImageData(width: 664, layout: CONSTRAINED)
		}
	}
	caseStudy_apoella: file(relativePath: {eq: "eshopshopify/tp-casestudy-apoella.png"}) {
		childImageSharp {
			gatsbyImageData(width: 664, layout: CONSTRAINED)
		}
	}
	caseStudy_bioaroma: file(relativePath: {eq: "eshopshopify/tp-casestudy-bioaroma.png"}) {
		childImageSharp {
			gatsbyImageData(width: 664, layout: CONSTRAINED)
		}
	}
	caseStudy_desunglasses: file(relativePath: {eq: "eshopshopify/tp-casestudy-desunglasses.png"}) {
		childImageSharp {
			gatsbyImageData(width: 664, layout: CONSTRAINED)
		}
	}
	caseStudy_loumidis: file(relativePath: {eq: "eshopshopify/tp-casestudy-loumidis.png"}) {
		childImageSharp {
			gatsbyImageData(width: 664, layout: CONSTRAINED)
		}
	}
}
`)

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
        linkAlternate={false}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
        <link rel="alternate" href="https://think-plus.gr/kataskeyi-eshop-me-shopify/" hreflang="el-gr" />
        <link rel="alternate" href="https://think-plus.gr/en/shopify-development/" hreflang="en" />
      </Helmet>
      <Layout viewport={'full'}>
        <article className="landing-page">
          <HeroFullScreenPhoto
            heroImage={images.hero.childImageSharp.gatsbyImageData}
            heroContent={
              `<img src="/images/logos/shopify-experts-2.svg" alt="Shopify Experts | Think Plus - Advertising" />
              <div class="landing-page__heading">
                <h1>ESHOP DEVELOPMENT</h1>
                <h2>Your ecommerce solution</h2>
              </div>
              <div class="landing-page__heading-cta">
                <p class="h6 dec-element--line-horizontal">Call us at +30 210 6101478 <a href="tel: +302106101478" class="btn__theme ml-md-3 mt-3 mt-md-0">Call us</a></p>
                <p class="h6 dec-element--line-horizontal">or fill out this form and we will get back to you as soon as possible <a href="#contact_form_section" class="btn__theme ml-md-3 mt-3 mt-md-0">Contact form</a></p>
              </div>`
            }
          />
          <section className="landing-page__info background__theme text-align-center">
            <div className="container medium">
              <div className="row">
                <div className="col mb-5">
                  <h2>Looking for eshop development? <br/>We are already trusted by the biggest<br/> brands that choose Shopify!</h2>
                  <p className="h6">Our development and design skills, combined with many years of experience in designing and promoting a highly successful Shopify eshop, guarantee your success in ecommerce!</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h3 className="h6">
                    <strong className="h2">No. 1</strong> <br />
                    E-Commerce platform worldwide
                  </h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6">
                    More than <br />
                    <strong className="h2">1.000.000</strong> <br />
                    businesses in 175 countries trust Shopify
                  </h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6">
                    <strong className="h2">€140,76 billion</strong> <br />
                    total turnover to date
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="what-we-offer" id="what_we_offer">
            <div className="container medium">
              <header className="text-align-center">
                <h2 className="dec-header--underline">High cost?</h2>
                <div className="h4">
                  Of course not! <br />
                  Get our basic e-shop site package for €1,000. <br />
                  Full &amp; Functional E-Shop System with shopify. <br />
                  What is included?
                </div>
              </header>
              <div className="row checkmarks">
                <div className="col-md-6">
                  <p>Product collections to unlimited levels of collections and collection list.</p>
                  <p>Detailed presentation of each product or service with an unlimited number of photos, collection, barcode, vendor, description and price. Any other specifications may be included in the description.</p>
                  <p>Back Office system with Mobile Application of the online store for the management of orders and members - customers.</p>
                  <p>Complete management of products database with easy-to-use environment for additions, deletions and any kind of modifications.</p>
                  <p>Create an e-shop with all current navigation filters (tags).</p>
                  <p>Member-customer registration process (Sign In), Full text search and advanced search &amp; Shopping Basket.</p>
                  <p>"Collections" for the presentation of selected products on the main page and on the home page of each product category.</p>
                  <p>Automatic update of the administrator and email for each order.</p>
                  <p>Operation of related products.</p>
                  <p>Password sending service to the customer's email in case of loss of the password.</p>
                  <p>Abandoned Cart Recovery email sending service of selected products.</p>
                  <p>Coupon system with shareable link &amp; gift cards.</p>
                  <p>Possibility to register the visitor for newsletters &amp; email campaigns.</p>
                </div>
                <div className="col-md-6">
                  <p>Financial statistics about your business using reports.</p>
                  <p>Corporate presentation with a complete analysis of the company's history, activities, financial figures.</p>
                  <p>Select keywords &amp; Meta description for category and product webpages.</p>
                  <p>Select keywords &amp; Meta description for content WebPages.</p>
                  <p>Adjust shipping and tax costs for all countries.</p>
                  <p>Import products through CSV files.</p>
                  <p>The customer can intervene directly in the selection of products in the cart.</p>
                  <p>Complete list of offline payment methods: Bank deposit, Money transfer via e-banking, Cash on delivery, etc.</p>
                  <p>Full online payment support: Credit cards (Viva Payments), Paypal Express.</p>
                  <p>Full HTTPS / SSL support with free certificate.</p>
                  <p>Password protection in management access.</p>
                  <p>Automatic fraud analysis system in IP Location transactions.</p>
                  <p>24/7 Support for technical issues related to Shopify by phone, email and live chat.</p>
                  <p>Hosting with unlimited bandwidth and online storage.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="landing-page__faq">
            <div className="background__theme--dark">
              <SectionHeader
                title="Shopify"
                text="FAQ"
              />
            </div>
            <div className="container medium">
              <div className="row">
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Is Shopify available in every country?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Yes, Shopify is now available and very popular in nearly every country in the world, constantly gaining ground and popularity.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Do I need to be a web developer to create my own Shopify?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>No, it is not necessary, as Shopify's own management tool gives you the ability to create your own online store easily and without much technical knowledge. Of course, if you really want to take full advantage of Shopify's capabilities and meet your specialized needs, we recommend that you trust the experts of Think Plus.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Is Shopify after all, an affordable or expensive solution?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>At Think Plus, we don’t like to distinguish between inexpensive and expensive solutions, rather than correct or incorrect ones. Shopify is structured based on the philosophy, "scale up & pay as you go", as a result of which it provides excellent tools and automation to the user at a cost significantly lower than any other management system. In summary, Shopify is the right solution for anyone who wants to succeed in the world of e-commerce and Think Plus can point out the right way.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>What are the main advantages of Shopify over other management systems?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Some of the most important benefits of Shopify:</p>
                        <ol>
                          <li>
                            <header><strong>Customer History</strong></header>
                            <p>The field of your customers in the eshop itself, helps you to learn more about your customers, as well as their buying habits. You can also use this to locate both their contact information and their order history at a glance.</p>
                          </li>
                          <li>
                            <header><strong>Abandoned Cart Recovery</strong></header>
                            <p>In case of an unexpected exit of the customer from your eshop without completing the transaction and even if they had already placed products in their shopping cart, shopify lets you automatically send them a reminding email. It is estimated that about 1 in 3 orders are abandoned during the shopping completion process. This feature helps you attract your customers and increase your sales.</p>
                          </li>
                          <li>
                            <header><strong>Multi-Language Checkout</strong></header>
                            <p>With the ability to select from more than 50 languages ​​on the payment page and throughout the navigation, as well as the choice of a variety of local currencies, Shopify gives you the ability to surpass your national borders and to have a real international store. Your concern: "good product, great pricing, excellent performance marketing".</p>
                          </li>
                          <li>
                            <header><strong>Backend Mobile Application</strong></header>
                            <p>Shopify’s mobile app allows you to track all the important updates for your store and run it fully from your iOS or Android device. Use this powerful backend tool to check your customers' statistics, products, orders, details and maximize your turnover.</p>
                          </li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>What does Think Plus include in the basic Shopify eshop package?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul className="checkmarks">
                          <p>Categorization of products for unlimited levels of categories and subcategories</p>
                          <p>Detailed presentation of each product or service with an unlimited number of photos, category, subcategory, code, supplier company, description and price. Any other product specifications may be included in the description.</p>
                          <p>Back Office System with Mobile Application of the online store for order management and also members or customers</p>
                          <p>Complete database management of products with easy-to-use environment for additions, subtractions and any kind of modifications</p>
                          <p>Build an e-shop with all modern navigation filters (tags)</p>
                          <p>Member-customer registration process (Sign In), Search (full text) and advanced search &amp; Shopping Basket</p>
                          <p>"Collections" of presentation of selected products on the main page and on the homepage of each product category</p>
                          <p>Automatic email notification to the administrator for each and every order</p>
                          <p>Related products functionality</p>
                          <p>Customer password recovery service email in case of loss</p>
                          <p>Abandoned Cart Recovery email notification service of selected products</p>
                          <p>Coupon system with shareable link &amp; gift cards</p>
                          <p>Ability to register the visitor for newsletters &amp; email campaigns</p>
                          <p>Financial statistics about your shop using reports</p>
                          <p>Corporate presentation with a complete analysis of the company's history, activities, financial figures</p>
                          <p>Keywords &amp; meta description option for category and product pages</p>
                          <p>Keywords &amp; meta description option for content pages</p>
                          <p>Ability to configure Sitemap</p>
                          <p>Adjustable shipping and tax costs for all countries</p>
                          <p>Sales Channels on Facebook &amp; Instagram with Tags &amp; Product Catalog</p>
                          <p>Import products through CSV files</p>
                          <p>The customer can directly modify the selected products in the cart</p>
                          <p>The registered customer can modify the details of his account at any time</p>
                          <p>The registered customer can see the history of his orders</p>
                          <p>Complete list of offline payment methods: Bank deposit, Money transfer via e-banking, Cash on delivery, etc.</p>
                          <p>Full online payment support: Credit cards (Viva Payments), Paypal Express</p>
                          <p>Full HTTPS / SSL support with free certificate</p>
                          <p>Password protection in access management</p>
                          <p>Automatic fraud analysis system in IP Location transactions</p>
                          <p>24/7 Support for technical issues related to Shopify by phone, email and live chat</p>
                          <p>Unlimited bandwidth and online storage hosting</p>
                        </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="col-md-6">
                    <Accordion
                      className="accordion--theme"
                      allowZeroExpanded={true}
                    >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>To put it simply, why should I choose Shopify for my eshop?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>There are too many reasons for us; technical and others. But if we are to focus on non-technical reasons, these would be the following:</p>
                        <ul>
                          <li>It is the fastest growing ecommerce software in the world</li>
                          <li>There is a huge private company backing it up, with thousands of employees who develop the platform daily, the benefits of which are harvested by its end users; unlike any other open source management tool</li>
                          <li>Shopify's philosophy is sales and marketing oriented, and is self-structured to maximize sales</li>
                          <li>It is very simple to use unlike other management platforms</li>
                          <li>The first connection that facebook &amp; instagram established with the world of  ecommerce it was Shopify; and that says a lot…</li>
                          <li>It has been recorded that there are Shopify shops that make billions in turnover (Kylie Jenner)</li>
                          <li>It provides the best server in the world and strong levels of security and speed,</li>
                          <li>and many more reasons we would be happy to share with you in person, at Think Plus.</li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Can my shopify be shared with Skroutz?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Of course it can. One of the most important advantages of Shopify is the app store it comes with; just like your smartphone. You can find hundreds of applications, free and not, which you can easily install on your shopify and increase your sales. One of them is the application for Skroutz which is <a href="https://apps.shopify.com/export-product-skroutz-xml-feed" target="_blank" title="Shopify Skroutz xml feed app">completely free</a> and has been developed by Think Plus.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Can my shopify be shared with BestPrice?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Of course it can. One of the most important advantages of Shopify is the app store it comes with; just like your smartphone. You can find hundreds of applications, free and not, which you can easily install on your shopify and increase your sales. One of them is the application for BestPrice which is <a href="https://apps.shopify.com/export-product-best-price-xml-feed" target="_blank" title="Shopify Best Price xml feed app">completely free</a> and has been developed by Think Plus.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Can my shopify be shared with Glami?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Of course it can. One of the most important advantages of Shopify is the app store it comes with; just like your smartphone. You can find hundreds of applications, free and not, which you can easily install on your shopify and increase your sales. One of them is the application for Glami which is completely free and has been also developed by Think Plus.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Why choose Think Plus for my Shopify shop?</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>This is, of course, a question we would love to answer in person. But if we had to choose three main reasons, they would be:</p>
                        <ol>
                          <li>We are the company with the most experienced shopify team in Greece.</li>
                          <li>We are the only software company that developed our own <a href="https://apps.shopify.com/partners/think-plus1" target="_blank" title="Shopify Experts Greece - Think Plus">applications</a> for Shopify’s app store and received many positive reviews.</li>
                          <li>We are your strategic partner in the world of e-commerce as in addition to your shopify, we can provide you with all the necessary services to succeed in this highly competitive space.</li>
                        </ol>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
          <Partnerships />
          <OurPartners headerTitle="CLIENTS" />
          <Testimonials classname="background__theme--dark" />
          <section className="landing-page__case-studies background__theme">
						<div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">AEK FC</h2>
										<h3 className="h4">#FLYINGHIGH</h3>
                    <p>The most “footballish” eshop! AEK Athens and Think Plus, are playing together on the e-commerce field and scoring countless goals in selling favorite products!</p>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-0 background__white background-bleed__right">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={images.caseStudy_aek.childImageSharp.gatsbyImageData}
                      alt="AEK FC - Think Plus | Shopify Eshop" />
                    <a href="https://shop.aekfc.gr/" title="AEK FC - Shopify shop" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 background__white background-bleed__left">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={images.caseStudy_bioaroma.childImageSharp.gatsbyImageData}
                      alt="Bioaroma - Think Plus | Shopify Eshop" />
                    <a href="https://bioaromacrete.com/" title="Bioaroma - Shopify shop" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Bioaroma Crete</h2>
										<h3 className="h4">"Bio" logically anyone would love to have such an e-shop!</h3>
                    <p>"Bio" logically anyone would love to have such an e-shop! The new BIOAROMA Crete eshop on the Shopify platform is one of the most modern and competitive e-shops offering an impressive browsing and shopping experience!</p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Loumidis Coffeeshop</h2>
										<h3 className="h4">Greek family business since 1920</h3>
                    <p>Loumidi Coffee-shop’s new eshop. Unique aromas of coffee blending in a new unique shopify e-shop that meets the high expectations of our era; bringing the taste of tradition one click closer to every customer!</p>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-0 background__white background-bleed__right">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={images.caseStudy_loumidis.childImageSharp.gatsbyImageData}
                      alt="Καφεκοπτεία Λουμίδης - Think Plus | Shopify Eshop " />
                    <a href="https://www.kafekopteialoumidi.gr/" title="Loumidis - Shopify shop" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 background__white background-bleed__left">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={images.caseStudy_apoella.childImageSharp.gatsbyImageData}
                      alt="Apoella - Think Plus | Shopify Eshop" />
                    <a href="https://apoella.com/" title="Apoella - Shopify shop" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Apoella</h2>
                    <h3 className="h4">apoella.com apo (from) Think Plus!</h3>
                    <p>Apoella brand, having already established itself in some of the best luxury hotels in Greece and abroad, and also featuring an eclectic clientele in its network of concept stores, is now conquering the world of e-commerce with a custom designed shopify e-shop!</p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">DE-Sunglasses</h2>
										<h3 className="h4">Where design meets eyewear</h3>
                    <p>DE-SUNGLASSES is one of the most up-and-coming online stores in the eyewear sector with an unprecedented export turnover, while it has a complete and updated list of sunglasses for all styles and tastes at the best market prices.</p>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-0 background__white background-bleed__right">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={images.caseStudy_desunglasses.childImageSharp.gatsbyImageData}
                      alt="DE-Sunglasses - Think Plus | Shopify Eshop" />
                    <a href="https://www.de-sunglasses.com/" title="DE-Sunglasses - Shopify shop" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <WhyThinkPlus />
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageShopifyDevelopment
